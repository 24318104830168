<template>
  <ZForm @submit.prevent="handleSubmit">
    <ZFormGroup
      :label="t('newCollection.label')"
      label-for="favourites-newCollectionName"
      :state="!v$.name.$error"
      :invalid-feedback="
        v$.name.isUnique.$invalid ? t('newCollection.duplicateNameError') : t('errors.required')
      "
    >
      <ZFormInput
        id="favourites-newCollectionName"
        ref="collectionNameEl"
        autocomplete="off"
        :value="v$.name.$model"
        @input="v$.name.$model = $event"
      />
    </ZFormGroup>

    <div class="d-flex justify-content-between">
      <ZButton
        v-if="collection"
        variant="danger"
        link
        @click="handleDeleteCollection"
      >
        {{ t('deleteList') }}
      </ZButton>
      <ZButton
        v-else
        link
        variant="primary"
        @click="reset"
      >
        {{ t('actions.cancel') }}
      </ZButton>
      <ZButton
        type="submit"
        :disabled="loading"
      >
        {{ collection ? t('actions.saveChanges') : t('favourites.createNewList') }}
      </ZButton>
    </div>
  </ZForm>
</template>

<script setup lang="ts">
import type { ComponentPublicInstance } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

import type { Nullable } from '~/types'
import type { UserFavouritesCollection } from '~/types/rental-api-aliases'

const props = withDefaults(defineProps<{
  rvId?: string
  collection?: Nullable<UserFavouritesCollection>
}>(), {
  collection: null,
})

const emit = defineEmits(['collection-created', 'collection-renamed', 'collection-deleted', 'collection-reset'])

const { t } = useI18n()
const { collections, loading, addRvToCollection, deleteCollection, updateCollection, createCollection } = useFavouriteRVs()

const collectionNameEl = ref<ComponentPublicInstance<HTMLInputElement>>()

const form = ref({
  name: '',
})

const rules = {
  name: {
    required,
    isUnique(value: string) {
      return (
        !collections.value.some((collection) => {
          return collection.CollectionName?.toLowerCase() === value.toLowerCase()
        })
        || (Boolean(props.collection) && props.collection?.CollectionName?.toLowerCase() === value.toLowerCase())
      )
    },
  },
}

const v$ = useVuelidate(rules, form)

onMounted(() => {
  if (props.collection) {
    form.value.name = props.collection.CollectionName || ''
  }
  setTimeout(() => {
    collectionNameEl.value?.$el.focus()
  }, 100)
})

async function handleSubmit() {
  if (v$.value.$invalid) {
    v$.value.$touch()
    return
  }

  if (props.collection) {
    await handleRenameCollection()
  }
  else {
    await handleSaveNewCollection()
  }
}

async function handleDeleteCollection() {
  if (props.collection?.Id) {
    await deleteCollection(props.collection.Id)
    emit('collection-deleted')
  }
}

async function handleRenameCollection() {
  if (props.collection?.Id) {
    await updateCollection({
      collectionID: props.collection.Id,
      newCollectionName: form.value.name,
    })
    emit('collection-renamed')
  }
}

async function handleSaveNewCollection() {
  const collection = await createCollection(form.value.name)

  // Add the RV to the collection if an RV ID was provided.
  if (props.rvId && collection?.Id) {
    await addRvToCollection({ rvID: props.rvId, collectionID: collection.Id })
  }
  reset()
  emit('collection-created')
}

function reset() {
  v$.value.$reset()
  emit('collection-reset')
}
</script>

<i18n lang="json">
{
  "en": {
    "deleteList": "Delete list",
    "newCollection": {
      "label": "Name your new list",
      "duplicateNameError": "This name has already been used"
    }
  },
  "fr": {
    "deleteList": "Supprimer",
    "newCollection": {
      "label": "Nom de liste",
      "duplicateNameError": "Ce nom a déjà été utilisé"
    }
  }
}
</i18n>
